import * as React from "react";
import styled from "styled-components";
import { fonts, breakpoints } from "../styles/variables";
import Swiper from "react-id-swiper";
import StyledButton from "./StyledButton";
import DateFromTo from "./DateFromTo";
import SliderOptions from "./SliderOptions";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay]);
import SliderImg from "./SliderImg";

// Workaround see https://github.com/goblindegook/littlefoot/issues/338
require("swiper/swiper-bundle.min.css");

import { ReiseangebotSlide } from "../types/types";

const TitlePre = styled.h2`
  font-size: 22px;
  color: white;
  font-family: ${fonts.helvetica.regular};
  margin-bottom: 0;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: 30px;
  }
`;
const TitleMain = styled.h1`
  font-size: 32px;
  color: white;
  font-family: ${fonts.helvetica.black.condensed};
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: 48px;
  }
`;
const Date = styled.p`
  font-size: 22px;
  color: white;
  font-family: ${fonts.helvetica.regular};
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: 30px;
  }
`;
const ElementLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
`;

const SliderContentWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 70px;
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.md}px) {
    left: 90px;
    width: calc(100% - 180px);
  }
`;

const SlideImg = styled(SliderImg)`
  display: block;
  width: 100%;
  max-height: 700px;
`;

const ButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: block;
    font-size: 24px;
  }
`;

const StyledMainSlider = styled.div`
  position: relative;
  max-height: 700px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 6;
`;

const SlideWrapper = styled.div`
  max-height: 700px;
`;

const sizes = "";
const imgixParams = { fit: "crop", ar: "2.743:1", q: 50 };

interface MainSliderProps {
  slides: ReiseangebotSlide[];
}

const MainSlider: React.FC<MainSliderProps> = ({ slides }) => {
  return (
    <StyledMainSlider className="mainSlider">
      <Swiper {...SliderOptions}>
        {slides.map(
          ({
            id,
            uri,
            bildergalerie,
            title,
            reisetyp,
            datum,
            datumBis,
            buttonfarbe,
            buttontext,
          }) => (
            <SlideWrapper
              key={"mainSlider_" + id}
              className={"mainSlider_" + id}
            >
              <ElementLink href={uri}>
                <SlideImg
                  src={bildergalerie[0].url}
                  sizes={sizes}
                  imgixParams={imgixParams}
                  title={bildergalerie[0].title}
                  copyright={bildergalerie[0].copyright}
                />
                <SliderContentWrapper>
                  <TitlePre>{reisetyp.title}</TitlePre>
                  <TitleMain>{title}</TitleMain>
                  <Date>
                    <DateFromTo datum={datum} datumBis={datumBis} />
                  </Date>
                  <ButtonWrapper>
                    <StyledButton
                      buttonfarbe={buttonfarbe}
                      buttontext={buttontext}
                    />
                  </ButtonWrapper>
                </SliderContentWrapper>
              </ElementLink>
            </SlideWrapper>
          )
        )}
      </Swiper>
    </StyledMainSlider>
  );
};

export default MainSlider;
