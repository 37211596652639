import * as React from "react";
import styled from "styled-components";
import Wrapper from "./Wrapper";
import Content from "./Content";

const StyledNews = styled.div`
  background-color: lightblue;
  padding: 5px 25px 15px 25px;
  margin: 25px 0 0 0;
`;

const StyledContent = styled(Content)``;

interface NewsSectionProps {
  data: string;
}

const NewsSection: React.FC<NewsSectionProps> = ({ data }) => {
  return (
    <Wrapper>
      <StyledNews>
        <StyledContent content={data} />
      </StyledNews>
    </Wrapper>
  );
};

export default NewsSection;
