import * as React from "react";
import { graphql } from "gatsby";

import Page from "./Page";
import DefaultLayout from "../layouts/default";
import Traveloffers from "./Traveloffers";
import MainSlider from "./MainSlider";
import News from "./News";
import moment from "moment";

import { ReiseAngebot, ReiseangebotSlide } from "../types/types";
export const pageContext = { builddate: [`>= ${moment().format()}`] };
export const query = graphql`
  query($builddate: [CMS_QueryArgument]) {
    cms {
      reiseangebote: entries(
        section: "reiseangebote"
        status: ["live"]
        datum: $builddate
        orderBy: "datum ASC"
      ) {
        ... on CMS_reiseangebote_reiseangebote_Entry {
          id
          title
          slug
          uri
          datum
          datumBis
          bildergalerie {
            id
            title
            url
            img
            slug
            path
            ... on CMS_assets_Asset {
              copyright
            }
          }
          buttonfarbe
          buttontext
          sidebarContent
          mainContent
          metaDescription
          reisetyp {
            id
            title
            slug
          }
          status
          showInSlider
        }
      }
      tags {
        slug
        title
        ... on CMS_reisetyp_Tag {
          id
          title
          slug
        }
      }
      news: entry {
        ... on CMS_news_news_Entry {
          mainContent
        }
      }
    }
  }
`;

interface IndexPageProps {
  data: {
    cms: {
      reiseangebote: ReiseAngebot[];
      news: {};
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = (props) => {
  const { data } = props;
  const mainSliderSlides = data.cms.reiseangebote.filter(
    (reiseangebot: ReiseAngebot) => reiseangebot.showInSlider
  );

  let slides: ReiseangebotSlide[] = [];
  mainSliderSlides.map((slide) => {
    slides.push({
      id: slide.id,
      uri: slide.uri,
      title: slide.title,
      reisetyp: slide.reisetyp,
      datum: slide.datum,
      datumBis: slide.datumBis,
      buttonfarbe: slide.buttonfarbe,
      buttontext: slide.buttontext,
      bildergalerie: slide.bildergalerie,
    });
  });

  return (
    <DefaultLayout>
      <Page>
        {slides.length && <MainSlider slides={slides} />}
        {data.cms.news.mainContent ? (
          <News data={data.cms.news.mainContent} />
        ) : (
          ""
        )}
        <Traveloffers data={data} />
      </Page>
    </DefaultLayout>
  );
};

export default IndexPage;
