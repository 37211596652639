import * as React from "react";
import styled from "styled-components";
import Traveloffer from "./Traveloffer";
import { breakpoints, colors, fonts } from "../styles/variables";
import { useState } from "react";
import Wrapper from "./Wrapper";
import moment from "moment";

const TitleMain = styled.h1`
  font-size: 40px;
  color: ${colors.black};
  font-family: ${fonts.helvetica.regular};
  margin-top: 0;
`;

const StyledFilter = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0 50px 0;
  padding: 0;
  justify-content: flex-start;

  @media screen and (min-width: ${breakpoints.md}px) {
    justify-content: center;
    margin: 50px 0 75px 0;
  }
  @media screen and (min-width: ${breakpoints.lg}px) {
    margin: 75px 0 85px 0;
  }
`;

const StyledFilterItem = styled.li`
  font-family: ${fonts.helvetica.black.condensed};
  background-color: ${colors.brand};
  color: white;
  list-style-type: none;
  margin: 0 20px 10px 0;
  padding: 5px 13px;
  letter-spacing: 1px;
  transition: all 0.2s;
  cursor: pointer;
  bottom: -20px;
  font-size: 18px;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: 20px;
  }
  @media screen and (min-width: ${breakpoints.lg}px) {
    font-size: 20px;
  }
  &:hover {
    color: ${colors.brand};
    background-color: white;
  }
  &.is-active {
    color: ${colors.brand};
    background-color: white;
  }
`;

const StyledTravelOffers = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface TraveloffersProps {
  data: {
    cms: {
      reiseangebote: [];
    };
  };
}

const Traveloffers: React.FC<TraveloffersProps> = ({
  data: {
    cms: { reiseangebote },
  },
}) => {
  const [filter, setFilter] = useState(0);

  // nach dem Build abgelaufene Angebote ausschliessen
  let reiseangeboteActive = reiseangebote.filter(
    (element) => element.datum >= moment().format()
  );

  // nur in Angebote eingesetzte Tags ermitteln
  // Angebote nach Tag Filtern
  let filterList = [];

  for (const reiseangebot of reiseangeboteActive) {
    for (const reiseangebotReisetypen of reiseangebot.reisetyp) {
      if (
        !filterList.find(
          (reiseangebotReisetyp) =>
            reiseangebotReisetyp.id == reiseangebotReisetypen.id
        )
      ) {
        filterList.push(reiseangebotReisetypen);
      }
    }
  }
  let reiseangeboteFiltered = reiseangeboteActive.filter(
    (reiseangeboteActive) => {
      return reiseangeboteActive.reisetyp.find(
        (element) => element.id === filter
      );
    }
  );
  if (filter === 0) {
    reiseangeboteFiltered = reiseangeboteActive;
  }

  return (
    <Wrapper>
      <StyledFilter>
        <StyledFilterItem
          onClick={() => {
            setFilter(0);
          }}
          className={`${filter === 0 ? "is-active" : ""}`}
        >
          alle
        </StyledFilterItem>
        {filterList.map((filterListItem) => (
          <StyledFilterItem
            key={filterListItem.id}
            onClick={() => {
              setFilter(filterListItem.id);
            }}
            className={`${filter === filterListItem.id ? "is-active" : ""}`}
          >
            {filterListItem.title}
          </StyledFilterItem>
        ))}
      </StyledFilter>
      <TitleMain>Ramsauer Reiseangebote</TitleMain>
      <StyledTravelOffers>
        {reiseangeboteFiltered.map((reiseangebot) => (
          <Traveloffer {...reiseangebot} key={reiseangebot.id} />
        ))}
      </StyledTravelOffers>
    </Wrapper>
  );
};

export default Traveloffers;
