import * as React from "react";
import Swiper from "react-id-swiper";
import SliderImg from "./SliderImg";
import SliderOptions from "./SliderOptions";
import styled from "styled-components";
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);

// Workaround see https://github.com/goblindegook/littlefoot/issues/338
require("swiper/swiper-bundle.min.css");

import { ImgixParams, BilderGalerieBild } from "../types/types";

const SliderOptionsChanged = {
  ...SliderOptions,
  autoplay: false,
  speed: 1000,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return "";
    },
  },
};
const SlideWrapper = styled.div``;
const StyledOfferSlider = styled.div``;

interface SliderProps {
  gallery: BilderGalerieBild[];
  sizes: string;
  imgixParams: ImgixParams;
}

const Slider: React.FC<SliderProps> = ({ gallery, sizes, imgixParams }) => {
  return (
    <StyledOfferSlider>
      <Swiper {...SliderOptionsChanged}>
        {gallery.map(({ id, url, title }) => (
          <SlideWrapper key={"Slider_" + id} className={"Slider_" + id}>
            <SliderImg
              src={url}
              sizes={sizes}
              imgixParams={imgixParams}
              title={title}
              copyright={null}
            />
          </SlideWrapper>
        ))}
      </Swiper>
    </StyledOfferSlider>
  );
};

export default Slider;
