import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors, fonts } from "../styles/variables";
import Slider from "./Slider";
import StyledButton from "./StyledButton";
import DateFromTo from "./DateFromTo";

import { ReiseAngebot } from "../types/types";

const StyledTravelOffer = styled.div`
  padding: 0 0 64px 0;
  width: 100%;

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: calc(100% / 2);
    padding-right: 12px;
    padding-left: 12px;

    &:nth-of-type(2n - 1) {
      padding-left: 0;
    }
    &:nth-of-type(2n) {
      padding-right: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc(100% / 3);

    &:nth-of-type(3n + 1) {
      padding-right: 16px;
      padding-left: 0;
    }
    &:nth-of-type(3n + 2) {
      padding-right: 8px;
      padding-left: 8px;
    }
    &:nth-of-type(3n) {
      padding-right: 0;
      padding-left: 16px;
    }
  }
`;

const SliderWrapper = styled.div``;

const ElementLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;

  &:hover {
    color: ${colors.brand};

    button {
      color: ${colors.brand};
      background-color: white;
    }
  }
`;

const PreTitle = styled.div`
  font-size: 18px;
  font-family: ${fonts.helvetica.regular};
  margin-top: 15px;
`;

const Title = styled.div`
  font-size: 22px;
  font-family: ${fonts.helvetica.bold};
`;

const Date = styled.div`
  font-size: 18px;
  font-family: ${fonts.helvetica.regular};
`;
const ButtonWrapper = styled.div`
  font-size: 16px;
`;

const sizes = `(max-width: ${breakpoints.sm - 1}px) 100vw,(min-width: ${
  breakpoints.sm
}px) calc(${breakpoints.sm / 2}px - 24px),
(min-width: ${breakpoints.lg}px) calc(50vw - 36px), (min-width: ${
  breakpoints.xl
}px) calc((100vw - 48px) / 3)`;
const imgixParams = { fit: "crop", ar: "1.86:1", q: 50 };

interface TravelOfferProps extends ReiseAngebot {}

const Traveloffer: React.FC<TravelOfferProps> = ({
  id,
  uri,
  bildergalerie,
  reisetyp,
  title,
  datum,
  datumBis,
  buttonfarbe,
  buttontext,
}) => {
  return (
    <StyledTravelOffer key={id}>
      <ElementLink to={uri}>
        <SliderWrapper className={"mainSlider_" + id}>
          <Slider
            gallery={bildergalerie}
            sizes={sizes}
            imgixParams={imgixParams}
          />
        </SliderWrapper>
        <PreTitle>{reisetyp[0].title}</PreTitle>
        <Title>{title}</Title>
        <Date>
          <DateFromTo datum={datum} datumBis={datumBis} />
        </Date>
        <ButtonWrapper>
          <StyledButton buttonfarbe={buttonfarbe} buttontext={buttontext} />
        </ButtonWrapper>
      </ElementLink>
    </StyledTravelOffer>
  );
};

export default Traveloffer;
